import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addMultiplierState, addScore, setUnlockHistory } from '../../../state/lemonadeClickerSlice';
import { resourceStateSelector, unlockHistorySelector } from '../selectors';
import { UnlockData } from '../types';

const UPGRADES = Object.keys(UnlockData).map((el) => parseInt(el, 10));

type UpgradeListProps = {
  score: number;
};

export function UpgradeList({ score }: UpgradeListProps) {
  const resourceState = useSelector(resourceStateSelector);
  const unlockHistory = useSelector(unlockHistorySelector);

  const outstandingUpgrades = UPGRADES.reduce((acc, cur) => {
    // only show upgrades for base items we have purchased
    const unlocked = (resourceState[UnlockData[cur].type] ?? 0) >= 1;
    const purchased = unlockHistory[cur] ?? false;
    if (!unlocked || purchased) {
      return acc;
    }

    return {
      ...acc,
      ...{
        [cur]: {
          purchased,
          ...UnlockData[cur],
        },
      },
    };
  }, {});

  return (
    <Row xs="auto">
      {Object.keys(outstandingUpgrades).map((key) => {
        const id = parseInt(key, 10);
        return <UpgradeItem id={id} key={key} score={score} />;
      })}
    </Row>
  );
}

type UpgradeItemProps = { img?: string; id: number; score: number };
function UpgradeItem({ img, id, score }: UpgradeItemProps) {
  const background = img ? { backgroundImage: img } : { backgroundColor: 'rgb(255 130 130)' };
  const { title, description, cost, multiplier, type } = UnlockData[id];
  const dispatch = useDispatch();
  const inRange = score > cost * 0.8;
  const eligible = score > cost;

  if (!inRange) {
    return <></>;
  }

  return (
    <Col>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            ${cost}, Multiplier +{multiplier} - {description}
          </Tooltip>
        }
      >
        <div
          className="d-flex flex-column p-1"
          style={{
            cursor: 'pointer',
            ...background,
          }}
          onClick={() => {
            if (!eligible) {
              return;
            }

            dispatch(addScore(-UnlockData[id].cost));
            dispatch(
              setUnlockHistory({
                [id]: true,
              })
            );
            dispatch(
              addMultiplierState({
                type,
                multiplier,
              })
            );
          }}
        >
          ${cost} - {title} x{multiplier}
        </div>
      </OverlayTrigger>
    </Col>
  );
}
