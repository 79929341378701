import { Container, Row, Col } from 'react-bootstrap';
import { GameProps } from '../util';

function GameWindow({ onStart }: GameProps) {
  return (
    <Container>
      <Row className="p-5">
        <Col className="text-center">{onStart()}</Col>
      </Row>
    </Container>
  );
}

export default GameWindow;
