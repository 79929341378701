import { GameType } from '../shared/Types';

export enum CalcType {
  Pixels,
  Grid,
}

export enum Turn {
  X = 'X',
  O = 'O',
}

export type BoardMove = { [_: string]: Turn };

export interface State {
  mode?: GameType;
  player?: Turn;
  turn: Turn;
  board: BoardMove;
  clock: number;
  lastWinner?: Turn;
  score: {
    [key in Turn]: number;
  };
  aiState: AI_PLAYER_STATE;
}

export const enum AI_PLAYER_STATE {
  DELAY,
  PICK_NEXT_MOVE,
  WAIT_FOR_TURN,
}
