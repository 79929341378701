import { AppDispatch } from '../../state/store';
import { Canvas2D } from '../shared/Canvas2D';
import { Coordinate } from '../shared/Coordinate';
import { RenderingSettings } from '../shared/Types';
import { min, PI2 } from '../shared/Util';
import { GRID_SIZE, X_SLOPE_PAD, Y_SLOPE_PAD } from './config';
import { State, Turn } from './types';

export class Canvas extends Canvas2D {
  dispatch: AppDispatch;
  getState: () => State;

  constructor(canvasRef: HTMLCanvasElement, dispatch: AppDispatch, getState: () => State) {
    super(canvasRef);
    this.dispatch = dispatch;
    this.getState = getState;
  }

  /**
   * Render the o player
   *
   * @param center
   * @param settings
   */
  drawO(center: Coordinate, player: Turn, settings: RenderingSettings = {}) {
    const xSize = this.canvas.width / GRID_SIZE / 2 - X_SLOPE_PAD * 2;
    const ySize = this.canvas.height / GRID_SIZE / 2 - Y_SLOPE_PAD * 2;
    const rad = min(xSize, ySize);

    settings = {
      ...settings,
      fillStyle: player === Turn.PLAYER1 ? '#e74c3c' : '#3498db',
    };

    this.ctx.save();
    this._parseSettings(settings);

    this.ctx.beginPath();
    this.ctx.arc(center.x, center.y, rad, 0, PI2);
    this.ctx.closePath();
    this.ctx.fill();

    this.ctx.restore();
  }
}
