import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GameType } from '../games/shared/Types';
import { random } from '../games/shared/Util';
import { AI_PLAYER_STATE, State, Turn } from '../games/tictactoe/types';

const initialState: State = {
  turn: random() > 0.5 ? Turn.X : Turn.O,
  clock: 0,
  board: {},
  lastWinner: undefined,
  score: {
    [Turn.X]: 0,
    [Turn.O]: 0,
  },
  aiState: AI_PLAYER_STATE.WAIT_FOR_TURN,
};

const tictactoeSlice = createSlice({
  name: 'tictactoe',
  initialState,
  reducers: {
    setMode(state, action: PayloadAction<GameType>) {
      state.mode = action.payload;
    },
    setPlayer(state, action: PayloadAction<Turn>) {
      state.player = action.payload;
    },
    setTurn(state, action: PayloadAction<Turn>) {
      state.turn = action.payload;
    },
    setClock(state, action) {
      state.clock = action.payload;
    },
    setLastWinner(state, action) {
      state.lastWinner = action.payload;
    },
    updateBoard(state, action) {
      state.board = { ...state.board, ...action.payload };
    },
    incrementScore(state, action: PayloadAction<Turn>) {
      state.score = {
        ...state.score,
        [action.payload]: state.score[action.payload] + 1,
      };
    },
    resetBoard(state) {
      state.board = {};
    },
    setAiState(state, action: PayloadAction<AI_PLAYER_STATE>) {
      state.aiState = action.payload;
    },
  },
});

export const {
  setMode,
  setPlayer,
  setTurn,
  setClock,
  setLastWinner,
  updateBoard,
  incrementScore,
  resetBoard,
  setAiState,
} = tictactoeSlice.actions;

export default tictactoeSlice.reducer;
