import { Col, Container, Row } from 'react-bootstrap';
import { GameProps } from '../util';
import GameCard from './GameCard';

export interface GamesListProps {
  list: GameProps[];
}

function GamesList({ list }: GamesListProps) {
  return (
    <Container>
      <Row className="">
        {list.map((game: GameProps) => (
          <Col className="d-flex justify-content-center">
            <GameCard key={game.link} {...game} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default GamesList;
