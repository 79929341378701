import { setMode } from '../../state/roll4Slice';
import store from '../../state/store';
import { RenderLoop } from '../shared/RenderLoop';
import { GameType } from '../shared/Types';
import { UpdateLoop } from '../shared/UpdateLoop';
import { Canvas } from './canvas';
import { Board } from './entities/Board';
import { Roll4Context } from './types';

const dispatch = store.dispatch;
const getState = () => store.getState().roll4;

export const start = (_canvas: HTMLCanvasElement, mode: GameType) => {
  dispatch(setMode(mode));
  const canvas = new Canvas(_canvas, dispatch, getState);
  const context: Roll4Context = {
    canvas,
    dispatch,
    getState,
  };
  const board = new Board(context);

  new RenderLoop((delta: number = 0) => {
    board.render(delta, context);
  });

  new UpdateLoop((delta: number = 0) => {
    board.update(delta, context);
  }, 1000 / 60);
};
