import { Col, Container, Row } from 'react-bootstrap';
import { Route } from 'wouter';
import Footer from './components/Footer';
import GamesList from './components/GamesList';
import GameWindow from './components/GameWindow';
import Header from './components/Header';
import { LemonadeClicker } from './games/lemonade-clicker';
import { Roll4 } from './games/roll4';
import { TicTacToe } from './games/tictactoe';
import { GameProps, ROUTER_LINKS } from './util';

const gamesList: GameProps[] = [LemonadeClicker, Roll4, TicTacToe];

function App() {
  return (
    <div className="h-100 w-100 d-flex flex-column">
      {Header()}
      <main>
        <Route path="/">
          <HomePage />
        </Route>
        <Route path={ROUTER_LINKS.TIC_TAC_TOE}>
          <GameWindow {...TicTacToe} />
        </Route>
        <Route path={ROUTER_LINKS.ROLL_4}>
          <GameWindow {...Roll4} />
        </Route>
        <Route path={ROUTER_LINKS.LEMONADE_CLICKER}>
          <GameWindow {...LemonadeClicker} />
        </Route>
      </main>
      {Footer()}
    </div>
  );
}

function HomePage() {
  return (
    <Container>
      <Row className="p-5">
        <Col className="text-center">
          <h1>Welcome</h1>
          <p>Welcome to my games portal. This is where I experiment with new and old tech.</p>
          <p>
            If you have fun, tell a friend! If you find bugs, <a href="mailto:zackurben@gmail.com">let me know!</a>
          </p>
        </Col>
      </Row>
      <GamesList list={gamesList} />
    </Container>
  );
}

export default App;
