import store from '../../state/store';
import {
  incrementScore,
  resetBoard,
  setAiState,
  setClock,
  setLastWinner,
  setMode,
  setPlayer,
  setTurn,
  updateBoard,
} from '../../state/tictactoeSlice';
import { Coordinate } from '../shared/Coordinate';
import { onClick } from '../shared/input';
import { RenderLoop } from '../shared/RenderLoop';
import { GameType } from '../shared/Types';
import { UpdateLoop } from '../shared/UpdateLoop';
import { random, tileId2D } from '../shared/Util';
import { AI } from './ai';
import { BoardSolution } from './BoardSolution';
import { Canvas } from './canvas';
import { GRID_SIZE, MOVE_CLOCK } from './config';
import { AI_PLAYER_STATE, Turn } from './types';

const dispatch = store.dispatch;
const getState = () => store.getState().tictactoe;
const aiPlayer = new AI(dispatch, getState);
const playerSolution = new BoardSolution(dispatch, getState);

const getNextTurn = (turn: Turn) => (turn === Turn.X ? Turn.O : Turn.X);

const processClick = (position: Coordinate) => {
  const { turn, board } = getState();
  const { x, y } = position;
  if (checkWin(x, y, turn)) {
    dispatch(incrementScore(turn));
    dispatch(setLastWinner(turn));
    restart();
  } else if (Object.keys(board).length === GRID_SIZE ** 2) {
    dispatch(setLastWinner(undefined));
    restart();
  } else {
    nextTurn();
  }
};

/**
 * Reset the round
 */
const restart = () => {
  dispatch(setTurn(random() > 0.5 ? Turn.X : Turn.O));
  dispatch(resetBoard());
  dispatch(setClock(MOVE_CLOCK));

  playerSolution.reset();
  aiPlayer.reset();
};

/**
 * Iterate to the next turn state
 */
const nextTurn = () => {
  const { turn, mode } = getState();
  const nextTurn = getNextTurn(turn);
  if (mode === GameType.LOCAL_MULTI) {
    dispatch(setPlayer(nextTurn));
  }

  dispatch(setTurn(nextTurn));
  dispatch(setClock(MOVE_CLOCK));
};

/**
 * Primary game loop
 *
 * @param mode
 */
export const start = (_canvas: HTMLCanvasElement, mode: GameType) => {
  dispatch(setMode(mode));
  const canvas = new Canvas(_canvas, dispatch, getState);

  // start the game
  restart();

  // Set the current player for the session
  dispatch(setPlayer(getState().turn));

  new RenderLoop((delta: number = 0) => {
    canvas.render(delta);
  });

  new UpdateLoop((delta: number = 0) => {
    const { turn, player, aiState, clock } = getState();

    // update aiPlayer state
    if (mode === GameType.LOCAL_SINGLE) {
      if (turn !== player && aiState === AI_PLAYER_STATE.WAIT_FOR_TURN) {
        dispatch(setAiState(AI_PLAYER_STATE.PICK_NEXT_MOVE));
      }
      aiPlayer.update(delta, processClick, playerSolution);
    }

    // update time
    const newTime = clock - 1;
    if (newTime <= 0) {
      nextTurn();
    } else {
      dispatch(setClock(newTime));
    }
  });

  // Register input events
  onClick(_canvas, (ev: MouseEvent) => {
    const { turn, player } = getState();
    if (player !== turn) return; // Skip processing this if its not the players turn

    const { offsetX: x, offsetY: y } = ev;
    const { x: xTry, y: yTry } = canvas.getGridCell({ x, y }, GRID_SIZE, GRID_SIZE);
    if (!validTurn(xTry, yTry)) return; // Skip processing this if the turn isn't valid
    const nextMove = tileId2D(xTry, yTry);
    playerSolution.removeMove(nextMove);
    aiPlayer.boardSolution.removeMove(nextMove, true);
    processClick({ x: xTry, y: yTry });
  });

  /**
   * Determine if a (grid) turn is valid
   *
   * @param x {number} - the grid coord
   * @param y {number} - the grid coord
   * @returns boolean
   */
  const validTurn = (x: number, y: number): boolean => {
    const { board, turn } = getState();
    const cell = tileId2D(x, y);
    if (board[cell]) return false;

    dispatch(
      updateBoard({
        [cell]: turn,
      })
    );
    return true;
  };
};

/**
 * The last play in grid coords
 *
 * @param lastPlay
 */
const checkWin = (x: number, y: number, player: Turn): boolean =>
  checkHorizontal({ x, y }, player) || checkVertical({ x, y }, player) || checkDiagonal({ x, y }, player);

/**
 * Check - moves
 *
 * @param lastPlay
 * @param player
 * @returns
 */
const checkHorizontal = (lastPlay: Coordinate, player: Turn): boolean => {
  const { board } = getState();
  for (let x = 0; x < GRID_SIZE; x++) {
    let tile = tileId2D(x, lastPlay.y);
    if (board[tile] !== player) {
      return false;
    }
  }

  return true;
};

/**
 * Check | moves
 *
 * @param lastPlay
 * @param player
 * @returns
 */
const checkVertical = (lastPlay: Coordinate, player: Turn): boolean => {
  const { board } = getState();
  for (let y = 0; y < GRID_SIZE; y++) {
    let tile = tileId2D(lastPlay.x, y);
    if (board[tile] !== player) {
      return false;
    }
  }

  return true;
};

/**
 * Check X moves
 *
 * @param lastPlay
 * @param player
 * @returns
 */
const checkDiagonal = (lastPlay: Coordinate, player: Turn): boolean => {
  const { board } = getState();
  const downLTR = lastPlay.x === lastPlay.y;
  const upLTR = lastPlay.x === GRID_SIZE - 1 - lastPlay.y;
  if (!downLTR && !upLTR) return false;

  let validDownLTR = false;
  let validUpLTR = false;
  if (downLTR) {
    validDownLTR = true;

    for (let pos = 0; pos < GRID_SIZE; pos++) {
      if (!validDownLTR) break;

      let tile = tileId2D(pos, pos);
      if (board[tile] !== player) validDownLTR = false;
    }
  }
  if (upLTR) {
    validUpLTR = true;

    for (let x = 0; x < GRID_SIZE; x++) {
      if (!validUpLTR) break;

      let tile = tileId2D(x, GRID_SIZE - 1 - x);
      if (board[tile] !== player) validUpLTR = false;
    }
  }

  return validDownLTR || validUpLTR;
};
