import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { addMultiplierState, addScore } from '../../../state/lemonadeClickerSlice';
import { ResourceData, ResourceTypes } from '../types';

export type UnlockButtonProps = {
  img?: string;
  type: ResourceTypes;
  score: number;
};

export function UnlockButton({ img, type, score }: UnlockButtonProps) {
  const { title, cost } = ResourceData[type];
  const dispatch = useDispatch();
  const inRange = score >= cost * 0.8;
  const eligible = score >= cost;

  return useMemo(() => {
    const background = img ? { backgroundImage: img } : { backgroundColor: 'rgb(193,220,224)' };
    if (!inRange) {
      return <></>;
    }

    return (
      <div
        className="d-flex flex-column"
        style={{
          height: '5rem',
          cursor: 'pointer',
          ...background,
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (!eligible) {
            return;
          }

          dispatch(addScore(-cost));
          dispatch(addMultiplierState({ type, multiplier: 0 }));
        }}
      >
        <div className="d-flex flex-column flex-grow-1 text-center justify-content-center">
          Unlock {title}
          <br />${cost}
        </div>
        <div className="d-flex flex-column flex-grow-1 text-center justify-content-center"></div>
      </div>
    );
  }, [img, type, cost, inRange, eligible]);
}
