import {
  incrementScore,
  resetBoard,
  setClock,
  setGameState,
  setLastWinner,
  setPlayer,
  setTurn,
  updateBoard,
} from '../../../state/roll4Slice';
import { BoardSolution } from '../../shared/BoardSolution';
import { Component } from '../../shared/Component';
import { onClick, onKeydown } from '../../shared/input';
import { GameType } from '../../shared/Types';
import { rad, random, tileId2D } from '../../shared/Util';
import { CANVAS_HEIGHT, CANVAS_WIDTH, GRID_BORDER_WIDTH, GRID_LINE_WIDTH, GRID_SIZE, MOVE_CLOCK } from '../config';
import { AnimationFn, BoardMove, CleanUpFn, GameState, Roll4Context, State, Turn } from '../types';
import { AI } from './Ai';
import { Piece } from './Piece';

export class Board extends Component {
  player1Solution: BoardSolution<State>;
  player2Solution: BoardSolution<State>;
  aiPlayer: AI;

  // animation stuff
  rotation: number = 0;
  rotAnimationFn?: AnimationFn<number>;
  rotAnimationCb?: () => void;

  constructor(context: Roll4Context) {
    super();

    this.player1Solution = new BoardSolution<State>(context, GRID_SIZE);
    this.player2Solution = new BoardSolution<State>(context, GRID_SIZE);
    this.aiPlayer = new AI(context);

    // Register input events
    onClick(context.canvas.canvas, (ev: MouseEvent) => {
      const { turn, player, gameState } = context.getState();
      // Skip processing this if its not the players turn or the board is locked
      if (player !== turn || gameState === GameState.BOARD_LOCK) return;

      const { offsetX: x, offsetY: y } = ev;
      const { x: xTry, y: yTry } = context.canvas.getGridCell({ x, y }, GRID_SIZE, GRID_SIZE);
      this.takeTurn(xTry, yTry, context);
    });

    onKeydown((e: KeyboardEvent) => {
      if (e.code !== 'Space') return;

      this.rotation += 1;
    });

    // restart on init
    context.dispatch(setTurn(context.getState().turn));
    this.restart(context);
  }

  /* eslint react/require-render-return: 0 */
  render(deltaTime: number, context: Roll4Context): void {
    // redraw the board from scratch
    context.canvas.clear();

    context.canvas.ctx.save();
    context.canvas.ctx.translate(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2);
    context.canvas.ctx.rotate(rad(this.rotation));
    context.canvas.ctx.translate(-CANVAS_WIDTH / 2, -CANVAS_HEIGHT / 2);

    // render child components (pieces)
    super.render(deltaTime, context);

    // draw boarder and grid on-top of pieces to simulate how the physical game looks
    context.canvas.border(GRID_BORDER_WIDTH);
    context.canvas.grid(GRID_LINE_WIDTH, GRID_SIZE, GRID_SIZE);
    context.canvas.ctx.restore();
  }

  update(deltaTime: number, context: Roll4Context): void {
    const { clock, gameState, mode, turn, player } = context.getState();

    // update time
    const newTime = clock - deltaTime;
    if (newTime <= 0) {
      this.nextTurn(context);
    } else {
      context.dispatch(setClock(newTime));
    }

    // update rotation
    if ([GameState.ROLL_LEFT, GameState.ROLL_RIGHT].includes(gameState)) {
      this.rotAnimationFn = this.radialMotion(gameState === GameState.ROLL_RIGHT ? 90 : -90, () => {
        this.rotAnimationFn = undefined;
        this.rotAnimationCb = () => {
          this.reorientBoard(context);
          this.rotAnimationCb = undefined;
          this.nextTurn(context);
        };
        context.dispatch(setGameState(GameState.PLAY));
      });
      context.dispatch(setGameState(GameState.BOARD_LOCK));
    } else if (this.rotAnimationFn) {
      this.rotation = this.rotAnimationFn(deltaTime);
    }
    if (this.rotAnimationCb) {
      this.rotAnimationCb();
    }

    // update child components
    super.update(deltaTime, context);

    // update ai
    this.aiPlayer.update(deltaTime, this.player1Solution, this.player2Solution, this, context);
  }

  /**
   * Reset the round
   */
  restart(context: Roll4Context) {
    this.player1Solution.reset();
    this.player2Solution.reset();

    this.components.all().forEach((c) => {
      if (!(c instanceof Piece)) return;
      this.components.remove(c.id);
    });

    const _turn = random() > 0.5 ? Turn.PLAYER1 : Turn.PLAYER2;
    context.dispatch(setTurn(_turn));
    context.dispatch(resetBoard());
    context.dispatch(setClock(MOVE_CLOCK));

    // set the player for the session
    context.dispatch(setPlayer(_turn));
  }

  /**
   * Attempt to take a turn, returns if the operation was successful or not.
   *
   * @param x {number} - the grid coord
   * @param y {number} - the grid coord
   * @param context {Roll4Context} - the context
   * @returns boolean
   */
  takeTurn(x: number, y: number, context: Roll4Context): boolean {
    const { board, turn } = context.getState();

    // check if player clicked on a valid position
    const cell = tileId2D(x, y);
    if (board[cell]) return false;

    // get the lowest y value for the piece as the final resting point
    const realPosition = this.getPieceRestingPlace(board, x);
    const realCell = tileId2D(realPosition.x, realPosition.y);

    // Add the piece with animation
    this.components.add(new Piece(context, x, y, turn, realPosition.x, realPosition.y));

    // update ai
    this.player1Solution.removeMove(realCell, turn !== Turn.PLAYER1);
    this.player2Solution.removeMove(realCell, turn !== Turn.PLAYER2);

    if (this.checkGameOver(context)) {
      return true;
    }

    // go to the next move
    this.nextTurn(context);
    return true;
  }

  private checkGameOver(context: Roll4Context): boolean {
    const { board } = context.getState();
    const p1Win = this.player1Solution.hasWon();
    const p2Win = this.player2Solution.hasWon();
    if (p1Win || p2Win) {
      if (p1Win) {
        context.dispatch(incrementScore(Turn.PLAYER1));
        context.dispatch(setLastWinner(Turn.PLAYER1));
      } else {
        context.dispatch(incrementScore(Turn.PLAYER2));
        context.dispatch(setLastWinner(Turn.PLAYER2));
      }

      this.restart(context);
      return true;
    } else if (Object.keys(board).length === GRID_SIZE ** 2) {
      context.dispatch(setLastWinner(undefined));
      this.restart(context);
      return true;
    }

    return false;
  }

  getNextTurn(turn: Turn) {
    return turn === Turn.PLAYER1 ? Turn.PLAYER2 : Turn.PLAYER1;
  }

  /**
   * Iterate to the next turn state
   */
  nextTurn(context: Roll4Context): void {
    const { turn, mode } = context.getState();
    const nextTurn = this.getNextTurn(turn);
    if (mode === GameType.LOCAL_MULTI) {
      context.dispatch(setPlayer(nextTurn));
    }

    context.dispatch(setTurn(nextTurn));
    context.dispatch(setClock(MOVE_CLOCK));
  }

  private radialMotion(degrees: number, cleanUpFn: CleanUpFn<void>): AnimationFn<number> {
    this.rotation = 0;

    const totalRotTime = 500;
    let remRotTime = totalRotTime;
    return (deltaTime: number) => {
      remRotTime -= deltaTime;
      // animation complete
      if (remRotTime < 0) {
        cleanUpFn();
        return degrees;
      }

      const animationProgress = 1 - remRotTime / totalRotTime;
      return animationProgress * degrees;
    };
  }

  getPieceRestingPlace(board: BoardMove, xTile: number) {
    const target = new Array(GRID_SIZE)
      .fill(undefined)
      .map((_, i) => board[tileId2D(xTile, i)])
      .reverse();
    const firstIndexReverse = target.findIndex((i) => i === undefined);
    const finalYTile = GRID_SIZE - 1 - firstIndexReverse;

    return { x: xTile, y: finalYTile };
  }

  private reorientBoard(context: Roll4Context) {
    const isLeft = this.rotation === -90 ? true : false;

    let pieceMap: { [index: string]: Piece } = {};
    this.components.all().forEach((c) => {
      if (!(c instanceof Piece)) return;

      // shuffle pieces
      const oldCell = context.canvas.getGridCell(c.position, GRID_SIZE, GRID_SIZE);
      let newCell;
      if (isLeft) {
        newCell = {
          x: oldCell.y,
          y: GRID_SIZE - 1 - oldCell.x,
        };
        c.position = context.canvas.getTileCenterPx(newCell, GRID_SIZE, GRID_SIZE);
      } else {
        newCell = {
          x: GRID_SIZE - 1 - oldCell.y,
          y: oldCell.x,
        };
        c.position = context.canvas.getTileCenterPx(newCell, GRID_SIZE, GRID_SIZE);
      }

      pieceMap[tileId2D(newCell.x, newCell.y)] = c;
    });

    // clear the board state
    context.dispatch(resetBoard());

    // update the board
    let boardCache: BoardMove = {};
    let tempKey: string;
    for (let x = 0; x < GRID_SIZE; x++) {
      for (let y = GRID_SIZE - 1; y >= 0; y--) {
        tempKey = tileId2D(x, y);
        if (!pieceMap[tempKey]) continue;

        const dest = this.getPieceRestingPlace(boardCache, x);
        pieceMap[tempKey].position = context.canvas.getTileCenterPx({ x, y }, GRID_SIZE, GRID_SIZE);
        if (dest.y !== y) {
          pieceMap[tempKey].linearMotion(
            context.canvas.getTileCenterPx({ x: dest.x, y: dest.y }, GRID_SIZE, GRID_SIZE)
          );
        }
        const destKey = tileId2D(dest.x, dest.y);
        const update = {
          [destKey]: pieceMap[tempKey].turn,
        };
        boardCache = {
          ...boardCache,
          ...update,
        };
        context.dispatch(updateBoard(update));
      }
    }

    // rebuild the board solutions
    this.player1Solution.reset();
    this.player2Solution.reset();
    Object.keys(boardCache).forEach((key) => {
      let turn = boardCache[key];
      this.player1Solution.removeMove(key, turn !== Turn.PLAYER1);
      this.player2Solution.removeMove(key, turn !== Turn.PLAYER2);
    });

    // reset orientation
    this.rotation = 0;

    // check if game is over
    this.checkGameOver(context);
  }
}
