import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GameState, State, Turn } from '../games/roll4/types';
import { GameType } from '../games/shared/Types';
import { random } from '../games/shared/Util';

const initialState: State = {
  turn: random() > 0.5 ? Turn.PLAYER1 : Turn.PLAYER2,
  clock: 0,
  board: {},
  lastWinner: undefined,
  score: {
    [Turn.PLAYER1]: 0,
    [Turn.PLAYER2]: 0,
  },
  gameState: GameState.PLAY,
};

const roll4Slice = createSlice({
  name: 'roll4',
  initialState,
  reducers: {
    setMode(state, action: PayloadAction<GameType>) {
      state.mode = action.payload;
    },
    setPlayer(state, action: PayloadAction<Turn>) {
      state.player = action.payload;
    },
    setTurn(state, action: PayloadAction<Turn>) {
      state.turn = action.payload;
    },
    setClock(state, action) {
      state.clock = action.payload;
    },
    setLastWinner(state, action) {
      state.lastWinner = action.payload;
    },
    updateBoard(state, action) {
      state.board = { ...state.board, ...action.payload };
    },
    incrementScore(state, action: PayloadAction<Turn>) {
      state.score = {
        ...state.score,
        [action.payload]: state.score[action.payload] + 1,
      };
    },
    resetBoard(state) {
      state.board = {};
    },
    setGameState(state, action: PayloadAction<GameState>) {
      state.gameState = action.payload;
    },
  },
});

export const {
  setMode,
  setPlayer,
  setTurn,
  setClock,
  setLastWinner,
  updateBoard,
  incrementScore,
  resetBoard,
  setGameState,
} = roll4Slice.actions;

export default roll4Slice.reducer;
