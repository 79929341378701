import { Coordinate } from './Coordinate';

export const { floor, min, PI, random, max, ceil } = Math;
export const PI2: number = 2 * PI;
export const now = Date.now;
export const randArrayIndex = (len: number) => {
  let out = floor(random() * len);
  return out;
};
export const tileId2D = (x: number, y: number) => `${x},${y}`;
export const tileFromId2D = (tileId: string): Coordinate => {
  const [x, y] = tileId.split(',').map((i: string) => parseInt(i));
  return { x, y };
};

export const rad = (degrees: number) => (degrees * PI) / 180;

export const enum StoreLocation {
  lemonadeClicker = 'lemonadeClicker',
}
export const writeToStore = (location: StoreLocation, data: any) => {
  try {
    localStorage.setItem(location, JSON.stringify(data));
  } catch (e) {
    console.error(`Can't persist data!`);
  }
};
export const readFromStore = (location: StoreLocation) => {
  let data;
  try {
    data = JSON.parse(localStorage.getItem(location) ?? '{}');
  } catch (e) {
    console.error(`Can't read data!`);
    data = {};
  }

  return data;
};
