import { useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import image from '../../screenshots/roll4/roll4.jpg';
import { setGameState } from '../../state/roll4Slice';
import { RootState, useDispatch } from '../../state/store';
import { GameProps, ROUTER_LINKS } from '../../util';
import { GameType } from '../shared/Types';
import { ceil } from '../shared/Util';
import { CANVAS_HEIGHT, CANVAS_WIDTH } from './config';
import { start } from './game';
import { GameState, Turn } from './types';

function Game() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const dispatch = useDispatch();
  const [playing, setPlaying] = useState(false);
  const score = useSelector((state: RootState) => state.roll4.score);
  const lastWinner = useSelector((state: RootState) => state.roll4.lastWinner);
  const turn = useSelector((state: RootState) => state.roll4.turn);
  const player = useSelector((state: RootState) => state.roll4.player);
  const mode = useSelector((state: RootState) => state.roll4.mode);
  const clock = useSelector((state: RootState) => state.roll4.clock);
  const gameState = useSelector((state: RootState) => state.roll4.gameState);

  const formatTurn = () => {
    if (mode === GameType.LOCAL_MULTI) return turn;
    return turn === player ? `yours (${player})` : `theirs (${player === Turn.PLAYER1 ? Turn.PLAYER2 : Turn.PLAYER1})`;
  };

  return (
    <Container style={{ maxWidth: `${CANVAS_WIDTH}px` }} className="p-0">
      <h1>{Roll4.title}</h1>
      {!playing && (
        <Row className="p-1">
          <Button
            className="mb-1"
            onClick={() => {
              if (!canvasRef.current) return;

              setPlaying(true);
              start(canvasRef.current, GameType.LOCAL_SINGLE);
            }}
          >
            Single player
          </Button>
          <Button
            className="mb-1"
            onClick={() => {
              if (!canvasRef.current) return;

              setPlaying(true);
              start(canvasRef.current, GameType.LOCAL_MULTI);
            }}
          >
            Local Multiplayer
          </Button>
        </Row>
      )}
      <div hidden={!playing} className="text-start">
        <Row>
          <Col className="text-start">Player 1: {score[Turn.PLAYER1]}</Col>
          <Col className="text-center">{lastWinner ? `Last win: ${lastWinner}` : ``}</Col>
          <Col className="text-end">Player 2: {score[Turn.PLAYER2]}</Col>
        </Row>
        <Row>
          <Col className="text-start">Turn: {formatTurn()}</Col>
          <Col className="text-end">Move Clock: {ceil(clock / 1000)}</Col>
        </Row>
        <canvas width={CANVAS_WIDTH} height={CANVAS_HEIGHT} ref={canvasRef}></canvas>
        <Row>
          <Col>
            <Button
              className="w-100"
              disabled={gameState !== GameState.PLAY || turn !== player}
              onClick={() => dispatch(setGameState(GameState.ROLL_LEFT))}
            >
              Roll Left
            </Button>
          </Col>
          <Col>
            <Button
              className="w-100"
              disabled={gameState !== GameState.PLAY || turn !== player}
              onClick={() => dispatch(setGameState(GameState.ROLL_RIGHT))}
            >
              Roll Right
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export const Roll4: GameProps = {
  title: 'Roll4',
  description: 'A fun twist on Connect4!',
  link: ROUTER_LINKS.ROLL_4,
  img: image,
  isNew: false,
  onStart: Game,
};
