export const enum ROUTER_LINKS {
  HOME = '/',
  TIC_TAC_TOE = '/tictactoe',
  ROLL_4 = '/roll4',
  LEMONADE_CLICKER = '/lemonade-clicker',
}

export type GameStartFn = () => JSX.Element;
export interface GameProps {
  title: string;
  description: string;
  link: ROUTER_LINKS;
  img: string;
  isNew?: boolean;
  onStart: GameStartFn;
}
