import { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import image from '../../screenshots/lemonade-clicker/lemonade-clicker.jpg';
import { GameProps, ROUTER_LINKS } from '../../util';
import { Board } from './components/Board';
import { start } from './game';

function Game() {
  const [playing, setPlaying] = useState(false);

  return (
    <Container style={{ maxWidth: '600px' }} className="p-0">
      <h1>{LemonadeClicker.title}</h1>
      {!playing && (
        <div className="row p-1">
          <Button
            className="mb-1"
            onClick={() => {
              setPlaying(true);
              start();
            }}
          >
            Play!
          </Button>
        </div>
      )}
      <div hidden={!playing} className="text-start">
        <Board />
      </div>
    </Container>
  );
}

export const LemonadeClicker: GameProps = {
  title: 'Lemonade Clicker',
  description: 'An idle clicker take on the classic Lemonade Stand',
  link: ROUTER_LINKS.LEMONADE_CLICKER,
  img: image,
  isNew: true,
  onStart: Game,
};
