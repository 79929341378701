import { AppDispatch } from '../../state/store';
import { randArrayIndex } from '../shared/Util';
import { State } from './types';

type Solution = { [_: string]: Array<string> };

export class BoardSolution {
  dispatch: AppDispatch;
  getState: () => State;
  moves: Solution;

  constructor(dispatch: AppDispatch, getState: () => State) {
    this.dispatch = dispatch;
    this.getState = getState;
    this.moves = BoardSolution.getSolutions();
  }

  reset() {
    this.moves = BoardSolution.getSolutions();
  }

  static getSolutions(): Solution {
    return {
      row1: ['0,0', '1,0', '2,0'], // top row
      row2: ['0,1', '1,1', '2,1'],
      row3: ['0,2', '1,2', '2,2'],
      col1: ['0,0', '0,1', '0,2'], // left col
      col2: ['1,0', '1,1', '1,2'],
      col3: ['2,0', '2,1', '2,2'],
      diag1: ['0,0', '1,1', '2,2'], // bottom left to top right
      diag2: ['0,2', '1,1', '2,0'], // top left to bottom right
    };
  }

  winsNextTurn(): string | undefined {
    let out;
    Object.keys(this.moves).forEach((solution) => {
      if (this.moves[solution].length === 1) {
        out = this.moves[solution][0];
      }
    });

    return out;
  }

  removeMove(move: string, purgeAll: boolean = false) {
    this.moves = Object.keys(this.moves).reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: this.moves[cur].includes(move) && purgeAll ? [] : this.moves[cur].filter((key: string) => key !== move),
      };
    }, {});
  }

  getBestMove(): string | undefined {
    let best = Number.POSITIVE_INFINITY;
    let index: string | undefined = undefined;
    Object.keys(this.moves).forEach((option) => {
      if (this.moves[option].length < best) {
        best = option.length;
        index = option;
      }
    });

    if (!index) return undefined;
    return this.moves[index][randArrayIndex(this.moves[index].length)];
  }
}
