import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { clockSelector, resourceStateSelector, scoreSelector } from '../selectors';
import { ResourceTypes } from '../types';
import { ClickerButton } from './ClickerButton';
import { GetScore } from './GetScore';
import { UnlockButton } from './UnlockButton';
import { UpgradeList } from './UpgradeList';

export function Board() {
  const resourceState = useSelector(resourceStateSelector);
  const cupEnabled = (resourceState[ResourceTypes.CUP] ?? 0) >= 1;
  const pitcherEnabled = (resourceState[ResourceTypes.PITCHER] ?? 0) >= 1;
  const bottleEnabled = (resourceState[ResourceTypes.BOTTLE] ?? 0) >= 1;
  const canEnabled = (resourceState[ResourceTypes.CAN] ?? 0) >= 1;
  const concentrateEnabled = (resourceState[ResourceTypes.CONCENTRATE] ?? 0) >= 1;
  const powderEnabled = (resourceState[ResourceTypes.POWDER] ?? 0) >= 1;

  return (
    <Col
      style={{
        userSelect: 'none',
      }}
    >
      <ScoreBoard />
      <GetScore>
        {(score) => (
          <>
            <Row className="p-1">
              <Col>
                {cupEnabled ? (
                  <ClickerButton manual type={ResourceTypes.CUP} multiplier={resourceState[ResourceTypes.CUP]} />
                ) : (
                  <UnlockButton type={ResourceTypes.CUP} score={score} />
                )}
              </Col>
              <Col>
                {pitcherEnabled ? (
                  <ClickerButton type={ResourceTypes.PITCHER} multiplier={resourceState[ResourceTypes.PITCHER]} />
                ) : (
                  <UnlockButton type={ResourceTypes.PITCHER} score={score} />
                )}
              </Col>
            </Row>
            <Row className="p-1">
              <Col>
                {bottleEnabled ? (
                  <ClickerButton type={ResourceTypes.BOTTLE} multiplier={resourceState[ResourceTypes.BOTTLE]} />
                ) : (
                  <UnlockButton type={ResourceTypes.BOTTLE} score={score} />
                )}
              </Col>
              <Col>
                {canEnabled ? (
                  <ClickerButton type={ResourceTypes.CAN} multiplier={resourceState[ResourceTypes.CAN]} />
                ) : (
                  <UnlockButton type={ResourceTypes.CAN} score={score} />
                )}
              </Col>
            </Row>
            <Row className="p-1">
              <Col>
                {concentrateEnabled ? (
                  <ClickerButton
                    type={ResourceTypes.CONCENTRATE}
                    multiplier={resourceState[ResourceTypes.CONCENTRATE]}
                  />
                ) : (
                  <UnlockButton type={ResourceTypes.CONCENTRATE} score={score} />
                )}
              </Col>
              <Col>
                {powderEnabled ? (
                  <ClickerButton type={ResourceTypes.POWDER} multiplier={resourceState[ResourceTypes.POWDER]} />
                ) : (
                  <UnlockButton type={ResourceTypes.POWDER} score={score} />
                )}
              </Col>
            </Row>
            <UpgradeList score={score} />
          </>
        )}
      </GetScore>
    </Col>
  );
}

function ScoreBoard() {
  const { newClock } = useSelector(clockSelector);
  const score = useSelector(scoreSelector);
  return (
    <Row>
      <Col>Money: ${score}</Col>
    </Row>
  );
}
