import { Set } from 'immutable';
import { setGameState } from '../../../state/roll4Slice';
import { AppDispatch } from '../../../state/store';
import { BoardSolution } from '../../shared/BoardSolution';
import { GameType } from '../../shared/Types';
import { randArrayIndex, random, tileFromId2D } from '../../shared/Util';
import { MOVE_CLOCK } from '../config';
import { GameState, Roll4Context, State, Turn } from '../types';
import { Board } from './Board';

export const enum AI_PLAYER_STATE {
  DELAY,
  PICK_NEXT_MOVE,
  WAIT_FOR_TURN,
  WAIT_FOR_ROTATE,
}

export class AI {
  dispatch: AppDispatch;
  getState: () => State;
  nextMove?: string;
  nextRotate?: GameState.ROLL_LEFT | GameState.ROLL_RIGHT;
  moveTime: number = 0;
  aiState: AI_PLAYER_STATE;

  constructor(context: Roll4Context) {
    this.dispatch = context.dispatch;
    this.getState = context.getState;
    this.aiState = AI_PLAYER_STATE.WAIT_FOR_TURN;
  }

  update(
    deltaTime: number,
    player1Solution: BoardSolution<State>,
    player2Solution: BoardSolution<State>,
    board: Board,
    context: Roll4Context
  ) {
    const { mode, turn, player } = context.getState();
    const _player = player === Turn.PLAYER1 ? player1Solution : player2Solution;
    const _ai = player === Turn.PLAYER1 ? player2Solution : player1Solution;

    // update aiPlayer state
    if (mode !== GameType.LOCAL_SINGLE) return;
    if (this.aiState === AI_PLAYER_STATE.WAIT_FOR_ROTATE && turn === player) {
      // this fixes the ai from cheating and making 2 moves during the rotate window.
      this.aiState = AI_PLAYER_STATE.WAIT_FOR_TURN;
    }
    if (turn !== player && this.aiState === AI_PLAYER_STATE.WAIT_FOR_TURN) {
      this.aiState = AI_PLAYER_STATE.PICK_NEXT_MOVE;
    }

    if (this.aiState === AI_PLAYER_STATE.PICK_NEXT_MOVE) {
      this.moveTime = 1000 + random() * (MOVE_CLOCK / 8);

      const _rotate = random() > 0.8;
      if (_rotate) {
        this.nextRotate = random() > 0.5 ? GameState.ROLL_LEFT : GameState.ROLL_RIGHT;
      } else {
        this.nextMove = (random() > 0.5 ? _player.winsNextTurn() : _ai.getBestMove()) || this.getRandomMove(_ai);
      }

      this.aiState = AI_PLAYER_STATE.DELAY;
    } else if (this.aiState === AI_PLAYER_STATE.DELAY) {
      this.moveTime = this.moveTime - deltaTime;
      if (this.moveTime > 0) return;
      if (this.nextMove) {
        const { x, y } = tileFromId2D(this.nextMove);
        this.nextMove = undefined;
        board.takeTurn(x, y, context);
        this.aiState = AI_PLAYER_STATE.WAIT_FOR_TURN;
      } else if (this.nextRotate) {
        this.dispatch(setGameState(this.nextRotate));
        this.nextRotate = undefined;
        this.aiState = AI_PLAYER_STATE.WAIT_FOR_ROTATE;
      }
    }
  }

  getRandomMove(ai: BoardSolution<State>): string {
    const { board } = this.getState();
    const moves = Set(Object.values(ai.getSolutions()).flat())
      .subtract(Set(Object.keys(board)))
      .toArray();
    return moves[randArrayIndex(moves.length)];
  }
}
