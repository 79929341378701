// grid rendering props
export const GRID_LINE_WIDTH: number = 4;
export const GRID_BORDER_WIDTH: number = 8;
export const X_SLOPE_PAD: number = 10;
export const Y_SLOPE_PAD: number = 10;

// game opts
export const GRID_SIZE: number = 3;
export const MOVE_CLOCK: number = 20;

export const ALL_MOVES = ['0,0', '0,1', '0,2', '1,0', '1,1', '1,2', '2,0', '2,1', '2,2'];
