import { loopFn } from './Types';
import { max, now } from './Util';

export class UpdateLoop {
  last: number = 0; // the time in ms that the last frame started
  start: number = 0; // the time in ms that this frame started
  delta: number = 0; // the time in ms between frames
  elapsed: number = 0; // total time in ms that the current frame took
  sleep: number = 0; // the time to sleep in ms to match the desired tick rate
  tick: number; // the target update frequency in ms
  onLoop: loopFn;

  constructor(onLoop: loopFn, tick: number = 1000) {
    this.onLoop = onLoop;
    this.tick = tick;
    this.update();
  }

  update() {
    this.last = this.start || now();
    this.start = now();
    this.delta = this.start - this.last;

    // run the provided update loop.
    this.onLoop(this.delta);

    this.elapsed = now() - this.start;
    this.sleep = max(this.tick - this.elapsed, 0);
    setTimeout(this.update.bind(this), this.sleep);
  }
}
