import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as libUseDispatch } from 'react-redux';
import lemonadeClickerSlice from './lemonadeClickerSlice';
import roll4Slice from './roll4Slice';
import tictactoeSlice from './tictactoeSlice';

const store = configureStore({
  reducer: {
    tictactoe: tictactoeSlice,
    roll4: roll4Slice,
    lemonadeClicker: lemonadeClickerSlice,
  },
});

// RootState wrapper to fix ts types
export type RootState = ReturnType<typeof store.getState>;
export default store;

// useDispatch wrapper to fix ts types
export type AppDispatch = typeof store.dispatch;
export const useDispatch = () => libUseDispatch<AppDispatch>();
