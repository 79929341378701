import { useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import image from '../../screenshots/tictactoe/tictactoe.jpg';
import { RootState } from '../../state/store';
import { GameProps, ROUTER_LINKS } from '../../util';
import { GameType } from '../shared/Types';
import { start } from './game';
import { Turn } from './types';

function Game() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [playing, setPlaying] = useState(false);
  const score = useSelector((state: RootState) => state.tictactoe.score);
  const lastWinner = useSelector((state: RootState) => state.tictactoe.lastWinner);
  const turn = useSelector((state: RootState) => state.tictactoe.turn);
  const player = useSelector((state: RootState) => state.tictactoe.player);
  const mode = useSelector((state: RootState) => state.tictactoe.mode);
  const clock = useSelector((state: RootState) => state.tictactoe.clock);

  const formatTurn = () => {
    if (mode === GameType.LOCAL_MULTI) return turn;
    return turn === player ? `yours (${player})` : `theirs (${player === Turn.X ? Turn.O : Turn.X})`;
  };

  return (
    <Container style={{ maxWidth: '400px' }} className="p-0">
      <h1>Tic-Tac-Toe</h1>
      {!playing && (
        <div className="row p-1">
          <Button
            className="mb-1"
            onClick={() => {
              if (!canvasRef.current) return;

              setPlaying(true);
              start(canvasRef.current, GameType.LOCAL_SINGLE);
            }}
          >
            Single player
          </Button>
          <Button
            className="mb-1"
            onClick={() => {
              if (!canvasRef.current) return;

              setPlaying(true);
              start(canvasRef.current, GameType.LOCAL_MULTI);
            }}
          >
            Local Multiplayer
          </Button>
        </div>
      )}
      <div hidden={!playing} className="text-start">
        <Row>
          <Col>Player X: {score[Turn.X]}</Col>
          <Col>{lastWinner ? `Last win: ${lastWinner}` : ``}</Col>
          <Col>Player O: {score[Turn.O]}</Col>
        </Row>
        <Row>
          <Col>Turn: {formatTurn()}</Col>
          <Col />
          <Col>Move Clock: {clock}</Col>
        </Row>
        <canvas width="400" height="400" ref={canvasRef}></canvas>
      </div>
    </Container>
  );
}

export const TicTacToe: GameProps = {
  title: 'Tic Tac Toe',
  description: 'A timed classic!',
  link: ROUTER_LINKS.TIC_TAC_TOE,
  img: image,
  isNew: false,
  onStart: Game,
};
