import { loopFn } from './Types';

export class RenderLoop {
  start: number = 0; // The time in ms since the start of this frame.
  delta: number = 0; // The time in ms since the last frame.
  onLoop: loopFn;

  constructor(onLoop: loopFn) {
    this.onLoop = onLoop;
    this.render();
  }

  render(timestamp: number = 0) {
    if (!this.start) this.start = timestamp;

    this.delta = timestamp - this.start;
    this.start = timestamp;

    this.onLoop(this.delta);
    window.requestAnimationFrame(this.render.bind(this));
  }
}
