import { GameType, LoopContext } from '../shared/Types';
import { Canvas } from './canvas';

export const enum Turn {
  PLAYER1 = 'red',
  PLAYER2 = 'blue',
}

export interface State {
  mode?: GameType;
  player?: Turn;
  turn: Turn;
  board: BoardMove;
  clock: number;
  lastWinner?: Turn;
  score: {
    [key in Turn]: number;
  };
  gameState: GameState;
}

export type BoardMove = { [_: string]: Turn };

export type Roll4Context = LoopContext<Canvas, State>;

export const enum GameState {
  PLAY,
  BOARD_LOCK,
  ROLL_LEFT,
  ROLL_RIGHT,
}

export type CleanUpFn<CleanUpContext> = (context: CleanUpContext) => void;
export type AnimationFn<AnimationFnReturn> = (deltaTime: number) => AnimationFnReturn;
export type AnimationGeneratorFn<AnimationContext, CleanUpContext, AnimationFnReturn> = (
  context: AnimationContext,
  cleanUp: CleanUpFn<CleanUpContext>
) => AnimationFn<AnimationFnReturn>;
