import { AppDispatch } from '../../state/store';
import { randArrayIndex, tileId2D } from '../shared/Util';
import { SimpleContext } from './Types';

type Solution = { [_: string]: Array<string> };

export class BoardSolution<State> {
  dispatch: AppDispatch;
  getState: () => State;
  moves: Solution;
  size: number;

  // todo: add invalid index to make checking for wins easier
  invalid: string[] = [];

  constructor(context: SimpleContext, size: number) {
    this.dispatch = context.dispatch;
    this.getState = context.getState;
    this.size = size;
    this.moves = this.getSolutions();
  }

  reset() {
    this.moves = this.getSolutions();
    this.invalid = [];
  }

  getSolutions(): Solution {
    const rows = new Array(this.size).fill(undefined).reduce((acc, _, y) => {
      return {
        ...acc,
        [`row${y + 1}`]: new Array(this.size).fill(undefined).map((_, x) => tileId2D(x, y)),
      };
    }, {});
    const cols = new Array(this.size).fill(undefined).reduce((acc, _, x) => {
      return {
        ...acc,
        [`col${x + 1}`]: new Array(this.size).fill(undefined).map((_, y) => tileId2D(x, y)),
      };
    }, {});

    // calculate diags
    let diag1 = [];
    let diag2 = [];
    for (let x = 0; x < this.size; x++) {
      // top left to bot right diag
      for (let y = 0; y < this.size; y++) {
        if (x === y) {
          diag1.push(tileId2D(x, y));
        }
      }

      // bot left to top right diag
      for (let y = 0; y < this.size; y++) {
        if (x + y === this.size - 1) {
          diag2.push(tileId2D(x, y));
        }
      }
    }

    return {
      ...rows,
      ...cols,
      diag1,
      diag2,
    };
  }

  winsNextTurn(): string | undefined {
    let out;
    Object.keys(this.moves).forEach((solution) => {
      if (this.moves[solution].length === 1) {
        out = this.moves[solution][0];
      }
    });

    return out;
  }

  hasWon() {
    return (
      Object.keys(this.moves).filter((cur) => !this.invalid.includes(cur) && this.moves[cur].length === 0).length > 0
    );
  }

  removeMove(move: string, purgeAll: boolean = false) {
    this.moves = Object.keys(this.moves).reduce((acc, cur) => {
      let invalid = this.moves[cur].includes(move) && purgeAll;
      if (invalid) {
        this.invalid.push(cur);
      }

      return {
        ...acc,
        [cur]: invalid ? [] : this.moves[cur].filter((key: string) => key !== move),
      };
    }, {});
  }

  getBestMove(): string | undefined {
    let best = Number.POSITIVE_INFINITY;
    let index: string | undefined = undefined;
    Object.keys(this.moves).forEach((option) => {
      if (this.moves[option].length < best) {
        best = option.length;
        index = option;
      }
    });

    if (!index) return undefined;
    return this.moves[index][randArrayIndex(this.moves[index].length)];
  }
}
