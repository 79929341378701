import { useSelector } from 'react-redux';
import { scoreSelector } from '../selectors';

type GetScoreProps = {
  children: (score: number) => JSX.Element;
};

export function GetScore({ children }: GetScoreProps) {
  const score = useSelector(scoreSelector);
  return children(score);
}
