import { Container, Nav } from 'react-bootstrap';

function Footer() {
  return (
    <footer className="mt-auto p-2 bg-light">
      <Nav className="text-end">
        <Container>
          <Nav.Item>
            <Nav.Link href="https://twitter.com/zackurben">&copy; Zack Urben</Nav.Link>
          </Nav.Item>
        </Container>
      </Nav>
    </footer>
  );
}

export default Footer;
