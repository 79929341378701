import { addScore, setMultiplierState, setUnlockHistory, updateClock } from '../../state/lemonadeClickerSlice';
import store from '../../state/store';
import { RenderLoop } from '../shared/RenderLoop';
import { UpdateLoop } from '../shared/UpdateLoop';
import { readFromStore, StoreLocation, writeToStore } from '../shared/Util';
import { State } from './types';

const dispatch = store.dispatch;
const getState = () => store.getState().lemonadeClicker;

const SAVE_TIME = 10000; // 10 seconds
let lastSave = 0;

const parseSaveData = (data: Partial<State> = {}) => {
  const { score, resourceState, unlockHistory } = data;

  if (score) {
    dispatch(addScore(score));
  }
  if (resourceState) {
    dispatch(setMultiplierState(resourceState));
  }
  if (unlockHistory) {
    dispatch(setUnlockHistory(unlockHistory));
  }
};

/**
 * Primary game loop
 *
 * @param mode
 */
export const start = () => {
  // load game state
  const data = readFromStore(StoreLocation.lemonadeClicker);
  parseSaveData(data);

  new RenderLoop((delta: number = 0) => {});

  new UpdateLoop((delta: number = 0) => {
    dispatch(updateClock(delta));

    lastSave -= delta;
    if (lastSave <= 0) {
      const { score, resourceState, unlockHistory } = getState();
      writeToStore(StoreLocation.lemonadeClicker, {
        score,
        resourceState,
        unlockHistory,
      });
      lastSave = SAVE_TIME;
    }
  }, 100);
};
