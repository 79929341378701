import { Set } from 'immutable';
import { AppDispatch } from '../../state/store';
import { setAiState, updateBoard } from '../../state/tictactoeSlice';
import { onClickFn } from '../shared/Types';
import { randArrayIndex, random, tileFromId2D } from '../shared/Util';
import { BoardSolution } from './BoardSolution';
import { ALL_MOVES, MOVE_CLOCK } from './config';
import { AI_PLAYER_STATE, State, Turn } from './types';

export class AI {
  dispatch: AppDispatch;
  getState: () => State;
  nextMove?: string;
  moveTime: number = 0;
  boardSolution: BoardSolution;

  constructor(dispatch: AppDispatch, getState: () => State) {
    this.dispatch = dispatch;
    this.getState = getState;
    this.boardSolution = new BoardSolution(dispatch, getState);
  }

  update(deltaTime: number, processClick: onClickFn, playerSolution: BoardSolution) {
    const { aiState } = this.getState();

    if (aiState === AI_PLAYER_STATE.PICK_NEXT_MOVE) {
      this.moveTime = random() * (MOVE_CLOCK / 3);
      this.nextMove =
        (random() > 0.5 ? playerSolution.winsNextTurn() : this.boardSolution.getBestMove()) || this.getRandomMove();
      this.dispatch(setAiState(AI_PLAYER_STATE.DELAY));
    } else if (aiState === AI_PLAYER_STATE.DELAY) {
      const remaining = this.moveTime - deltaTime;
      if (remaining > 0) {
        this.moveTime = remaining;
      } else {
        if (this.nextMove) {
          this.dispatch(
            updateBoard({
              [this.nextMove]: this.getTurnId(),
            })
          );
          processClick(tileFromId2D(this.nextMove));
          this.boardSolution.removeMove(this.nextMove);
          playerSolution.removeMove(this.nextMove, true);
          this.nextMove = undefined;
        }

        this.dispatch(setAiState(AI_PLAYER_STATE.WAIT_FOR_TURN));
      }
    }
  }

  reset() {
    this.boardSolution.reset();
  }

  getTurnId() {
    const { player } = this.getState();
    return player === Turn.X ? Turn.O : Turn.X;
  }

  getRandomMove(): string {
    const { board } = this.getState();
    const moves = Set(ALL_MOVES)
      .subtract(Set(Object.keys(board)))
      .toArray();
    return moves[randArrayIndex(moves.length)];
  }
}
