import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addScore } from '../../../state/lemonadeClickerSlice';
import { clockSelector } from '../selectors';
import { ResourceData, ResourceTypes } from '../types';
import { getProgress } from '../util';

export type ClickerButtonProps = {
  manual?: boolean;
  img?: string;
  type: ResourceTypes;
  multiplier?: number;
};

export function ClickerButton(props: ClickerButtonProps) {
  const { manual = false, img, type, multiplier = 1 } = props;
  const { title, score } = ResourceData[type];
  const [complete, setComplete] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (complete) {
      setComplete(false);
    }
  }, [complete]);

  const onComplete = useCallback(
    (delta: number) => {
      setComplete(true);
      dispatch(addScore(delta));
    },
    [setComplete, dispatch]
  );

  const progress = useMemo(
    () => <ClickerProgress type={type} onComplete={() => onComplete(score * multiplier)} />,
    [type, setComplete, onComplete, score, multiplier]
  );

  return useMemo(() => {
    const background = img ? { backgroundImage: img } : { backgroundColor: '#eeeeee' };

    return (
      <div
        className="d-flex flex-column"
        style={{
          height: '5rem',
          opacity: !complete ? 1 : 0.75,
          cursor: 'pointer',
          ...background,
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          // Skip additional processing for non-manual buttons
          if (!manual) return;

          // Trigger "complete" for manual button
          onComplete(score * multiplier);
        }}
      >
        <div className="px-2">
          <div className="d-flex flex-grow-1">
            <span>+{score}</span>
            <span className="d-flex flex-grow-1 justify-content-end">x{multiplier}</span>
          </div>
        </div>
        <div className="d-flex flex-column flex-grow-1 text-center justify-content-center">{title}</div>
        {!manual && progress}
      </div>
    );
  }, [img, complete, manual, onComplete, score, multiplier, title, progress]);
}

type ClickerProgressProps = {
  type: ResourceTypes;
  onComplete: () => void;
};
function ClickerProgress(props: ClickerProgressProps) {
  const { type, onComplete } = props;
  const { oldClock, newClock } = useSelector(clockSelector);
  const [oldProgress, newProgress] = getProgress(type, oldClock, newClock);
  const complete = oldProgress > newProgress;

  useEffect(() => {
    if (complete) {
      onComplete();
    }
  }, [complete, onComplete]);

  return useMemo(() => <ProgressBar now={newProgress} style={{ borderRadius: 0 }} />, [newProgress]);
}
