import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GameState, ResourceState, ResourceTypes, State, UnlockState } from '../games/lemonade-clicker/types';

const initialState: State = {
  newClock: 0,
  oldClock: 0,
  score: 0,
  gameState: GameState.PLAY,
  resourceState: {
    [ResourceTypes.CUP]: 1,
  },
  unlockHistory: {},
};

const lemonadeClickerSlice = createSlice({
  name: 'lemonade-clicker',
  initialState,
  reducers: {
    updateClock(state, action: PayloadAction<number>) {
      state.oldClock = state.newClock;
      state.newClock += action.payload;
    },
    addScore(state, action: PayloadAction<number>) {
      state.score += action.payload;
    },
    setGameState(state, action: PayloadAction<GameState>) {
      state.gameState = action.payload;
    },
    setMultiplierState(state, action: PayloadAction<ResourceState>) {
      state.resourceState = {
        ...state.resourceState,
        ...action.payload,
      };
    },
    setUnlockHistory(state, action: PayloadAction<UnlockState>) {
      state.unlockHistory = {
        ...state.unlockHistory,
        ...action.payload,
      };
    },
    addMultiplierState(state, action: PayloadAction<{ type: number; multiplier: number }>) {
      const { type, multiplier } = action.payload;
      state.resourceState = {
        ...state.resourceState,
        ...{
          [type]: (state.resourceState[type] ?? 1) + multiplier,
        },
      };
    },
  },
});

export const { updateClock, addScore, setGameState, setMultiplierState, setUnlockHistory, addMultiplierState } =
  lemonadeClickerSlice.actions;

export default lemonadeClickerSlice.reducer;
