import { AppDispatch } from '../../state/store';
import { Canvas2D } from '../shared/Canvas2D';
import { Coordinate } from '../shared/Coordinate';
import { RenderingSettings } from '../shared/Types';
import { min, PI2, tileFromId2D } from '../shared/Util';
import { GRID_BORDER_WIDTH, GRID_LINE_WIDTH, GRID_SIZE, X_SLOPE_PAD, Y_SLOPE_PAD } from './config';
import { State, Turn } from './types';

export class Canvas extends Canvas2D {
  dispatch: AppDispatch;
  getState: () => State;

  constructor(canvasRef: HTMLCanvasElement, dispatch: AppDispatch, getState: () => State) {
    super(canvasRef);
    this.dispatch = dispatch;
    this.getState = getState;
  }

  /**
   * Clear the whole canvas and redraw the board.
   */
  render(deltaTime: number) {
    this.clear();
    this.border(GRID_BORDER_WIDTH);
    this.grid(GRID_LINE_WIDTH, GRID_SIZE, GRID_SIZE);
    this.positions();
  }

  /**
   * Render all the board positions
   */
  positions() {
    const { board } = this.getState();

    Object.keys(board).forEach((tileId) => {
      const player = board[tileId];
      const tile = tileFromId2D(tileId);

      player === Turn.X
        ? this.drawX(this.getTileCenterPx(tile, GRID_SIZE, GRID_SIZE))
        : this.drawO(this.getTileCenterPx(tile, GRID_SIZE, GRID_SIZE));
    });
  }

  /**
   * Render the x player
   *
   * @param center
   * @param slope
   * @param settings
   */
  drawX(center: Coordinate, settings?: RenderingSettings) {
    const xSlope = this.canvas.width / GRID_SIZE / 2 - X_SLOPE_PAD * 2;
    const ySlope = this.canvas.height / GRID_SIZE / 2 - Y_SLOPE_PAD * 2;

    this.ctx.save();
    this._parseSettings(settings);

    // top to bot LTR
    const topLeft: Coordinate = { x: center.x - xSlope, y: center.y - ySlope };
    const topRight: Coordinate = { x: center.x + xSlope, y: center.y - ySlope };
    const bottomLeft: Coordinate = { x: center.x - xSlope, y: center.y + ySlope };
    const bottomRight: Coordinate = { x: center.x + xSlope, y: center.y + ySlope };

    this.ctx.beginPath();
    this.ctx.moveTo(topLeft.x, topLeft.y);
    this.ctx.lineTo(bottomRight.x, bottomRight.y);

    this.ctx.moveTo(bottomLeft.x, bottomLeft.y);
    this.ctx.lineTo(topRight.x, topRight.y);
    this.ctx.closePath();
    this.ctx.stroke();

    this.ctx.restore();
  }

  /**
   * Render the o player
   *
   * @param center
   * @param settings
   */
  drawO(center: Coordinate, settings?: RenderingSettings) {
    const xSize = this.canvas.width / GRID_SIZE / 2 - X_SLOPE_PAD * 2;
    const ySize = this.canvas.height / GRID_SIZE / 2 - Y_SLOPE_PAD * 2;
    const rad = min(xSize, ySize);

    this.ctx.save();
    this._parseSettings(settings);

    this.ctx.beginPath();
    this.ctx.arc(center.x, center.y, rad, 0, PI2);
    this.ctx.closePath();
    this.ctx.stroke();

    this.ctx.restore();
  }
}
