import { getId } from './Id';
import { SimpleContext } from './Types';

export abstract class Entity {
  active: boolean = true;
  id: number;

  constructor() {
    this.id = getId();
  }

  abstract update(deltaTime: number, context: SimpleContext): void;
  abstract render(deltaTime: number, context: SimpleContext): void;
}
