import { RootState } from '../../state/store';

export const oldClockSelector = (state: RootState) => state.lemonadeClicker.oldClock;
export const newClockSelector = (state: RootState) => state.lemonadeClicker.newClock;
export const clockSelector = (state: RootState) => ({
  newClock: state.lemonadeClicker.newClock,
  oldClock: state.lemonadeClicker.oldClock,
});
export const scoreSelector = (state: RootState) => state.lemonadeClicker.score;
export const gameStateSelector = (state: RootState) => state.lemonadeClicker.gameState;
export const resourceStateSelector = (state: RootState) => state.lemonadeClicker.resourceState;
export const unlockHistorySelector = (state: RootState) => state.lemonadeClicker.unlockHistory;
