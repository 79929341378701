import { AppDispatch } from '../../state/store';
import { Canvas2D } from './Canvas2D';
import { Coordinate } from './Coordinate';

export type RenderingSettings = {
  lineWidth?: number;
  fillStyle?: string;
};

export enum EventType {
  Click = 'click',
}

export type loopFn = (deltaTime: number) => void;
export type onClickFn = (position: Coordinate) => void;

export enum GameType {
  LOCAL_SINGLE,
  LOCAL_MULTI,
  ONLINE,
}

export type LoopContext<canvas, state> = {
  canvas: canvas;
  dispatch: AppDispatch;
  getState: () => state;
};
export type SimpleContext = LoopContext<Canvas2D, any>;
