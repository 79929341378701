import { Badge, Button, Card, Col } from 'react-bootstrap';
import { useLocation } from 'wouter';
import { GameProps } from '../util';

function GameCard({ title, description, link, img, isNew }: GameProps) {
  const [, setLocation] = useLocation();

  return (
    <Card style={{ maxWidth: '18rem' }} as={Col}>
      <Card.Img variant="top" src={img} />
      <Card.Body className="text-center">
        <Card.Title>
          {title}
          {isNew && (
            <Badge bg="secondary" className="p-1 ms-1">
              New
            </Badge>
          )}
        </Card.Title>
        <Card.Text>{description}</Card.Text>
        <Button variant="primary" onClick={() => setLocation(link)}>
          Play!
        </Button>
      </Card.Body>
    </Card>
  );
}

export default GameCard;
