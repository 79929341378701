import { Coordinate } from './Coordinate';
import { RenderingSettings } from './Types';
import { floor } from './Util';

export abstract class Canvas2D {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;

  constructor(canvasRef: HTMLCanvasElement) {
    this.canvas = canvasRef;
    let temp = canvasRef.getContext('2d');

    if (!temp) {
      throw new Error(`Couldn't access canvas!`);
    }

    this.ctx = temp;
  }

  // Clear the whole canvas
  clear() {
    this.ctx.save();
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.restore();
  }

  /**
   * Render a border inside the canvas
   *
   * @param lineWidth
   */
  border(lineWidth: number) {
    const topLeft: Coordinate = { x: 0, y: 0 };
    const topRight: Coordinate = { x: this.canvas.width, y: 0 };
    const bottomLeft: Coordinate = { x: 0, y: this.canvas.height };
    const bottomRight: Coordinate = { x: this.canvas.width, y: this.canvas.height };
    const settings: RenderingSettings = {
      lineWidth,
    };

    this.line(topLeft, topRight, settings); // top
    this.line(topRight, bottomRight, settings); // right
    this.line(bottomLeft, bottomRight, settings); // bottom
    this.line(topLeft, bottomLeft, settings); // left
  }

  /**
   * Render a grid inside the canvas
   *
   * @param lineWidth
   * @param rows
   * @param cols
   */
  grid(lineWidth: number, rows: number, cols: number) {
    const width = this.canvas.width / rows;
    const height = this.canvas.height / cols;
    const settings: RenderingSettings = {
      lineWidth,
    };

    for (let row = 1; row < rows; row++) {
      this.line(
        {
          x: 0,
          y: row * height,
        },
        {
          x: this.canvas.width,
          y: row * height,
        },
        settings
      );
    }
    for (let col = 1; col < cols; col++) {
      this.line(
        {
          x: col * width,
          y: 0,
        },
        {
          x: col * width,
          y: this.canvas.height,
        },
        settings
      );
    }
  }

  /**
   * Line draw util
   *
   * @param origin
   * @param destination
   * @param settings
   */
  line(origin: Coordinate, destination: Coordinate, settings?: RenderingSettings) {
    this.ctx.save();
    this._parseSettings(settings);

    this.ctx.beginPath();
    this.ctx.moveTo(origin.x, origin.y);
    this.ctx.lineTo(destination.x, destination.y);
    this.ctx.closePath();
    this.ctx.stroke();

    this.ctx.restore();
  }

  /**
   * Get the center point px for the given tile coordinates
   *
   * @param point
   * @param rows
   * @param cols
   * @returns
   */
  getTileCenterPx(point: Coordinate, rows: number, cols: number): Coordinate {
    const xSize = this.canvas.width / rows;
    const halfX = xSize / 2;
    const ySize = this.canvas.height / cols;
    const halfY = ySize / 2;

    return {
      x: point.x * xSize + halfX,
      y: point.y * ySize + halfY,
    } as Coordinate;
  }

  /**
   * Get the grid cell for the input canvas coordinates
   *
   * @param point
   * @param rows
   * @param cols
   * @returns
   */
  getGridCell(point: Coordinate, rows: number, cols: number): Coordinate {
    const xSize = this.canvas.width / rows;
    const ySize = this.canvas.height / cols;

    return {
      x: floor(point.x / xSize),
      y: floor(point.y / ySize),
    } as Coordinate;
  }

  /**
   * RenderSettings parser, to make .save/.restore easier to use.
   *
   * @param settings
   */
  _parseSettings(settings?: RenderingSettings) {
    if (settings?.lineWidth) {
      this.ctx.lineWidth = settings.lineWidth;
    }
    if (settings?.fillStyle) {
      this.ctx.fillStyle = settings.fillStyle;
    }
  }
}
