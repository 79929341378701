import { updateBoard } from '../../../state/roll4Slice';
import { Component } from '../../shared/Component';
import { Coordinate } from '../../shared/Coordinate';
import { tileId2D } from '../../shared/Util';
import { GRID_SIZE } from '../config';
import { Roll4Context, Turn } from '../types';

export class Piece extends Component {
  position: Coordinate = new Coordinate(0, 0);
  turn: Turn;
  target?: Coordinate;
  steps: Coordinate[] = [];

  constructor(context: Roll4Context, initX: number, initY: number, turn: Turn, finalX?: number, finalY?: number) {
    super();
    this.turn = turn;

    // set the position
    this.position = context.canvas.getTileCenterPx({ x: initX, y: initY }, GRID_SIZE, GRID_SIZE);

    const _finalX = finalX !== undefined ? finalX : initX;
    const _finalY = finalY !== undefined ? finalY : initY;

    if (_finalX !== undefined || _finalY !== undefined) {
      this.linearMotion(
        context.canvas.getTileCenterPx(
          {
            x: _finalX,
            y: _finalY,
          },
          GRID_SIZE,
          GRID_SIZE
        )
      );
    }

    // store the tile at its final place, so we dont have to update the store multiple times
    // (the animation isn't long enough to warrant that)
    const cell = tileId2D(_finalX, _finalY);
    context.dispatch(
      updateBoard({
        [cell]: turn,
      })
    );
  }

  update(deltaTime: number, context: Roll4Context) {
    if (this.target) {
      const next = this.steps.pop();
      if (next) {
        this.position = next;
      }

      if (this.steps.length === 0) {
        this.position = this.target;
        this.target = undefined;
      }
    }
  }

  /* eslint react/require-render-return: 0 */
  render(deltaTime: number, context: Roll4Context) {
    context.canvas.drawO(this.position, this.turn);
  }

  // TODO: Refactor to function more like the board rotation
  linearMotion(target: Coordinate, steps = 25) {
    this.target = target;

    let deltaX = (this.position.x - this.target.x) / steps;
    let deltaY = (this.position.y - this.target.y) / steps;

    this.steps = new Array(steps)
      .fill(undefined)
      .map((_, i) => Coordinate.from(this.position, deltaX * i, deltaY * i))
      .reverse();
  }
}
