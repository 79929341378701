import { Entity } from './Entity';
import { SimpleContext } from './Types';

type ComponentMap = { [key: string]: Component };
class ComponentStruct {
  private _components: ComponentMap = {};

  add(component: Component) {
    this._components[component.id] = component;
  }

  remove(id: number) {
    delete this._components[id];
  }

  all() {
    return Object.values(this._components);
  }

  get(id?: number) {
    if (id === undefined) return this.all();
    return this._components[id];
  }

  private filter() {
    this.all().forEach((c) => {
      if (!c.active) this.remove(c.id);
    });
  }
}

export class Component extends Entity {
  components: ComponentStruct = new ComponentStruct();

  constructor() {
    super();
  }

  update(deltaTime: number, context: SimpleContext) {
    this.components.all().forEach((component) => component.active && component.update(deltaTime, context));
  }

  render(deltaTime: number, context: SimpleContext) {
    context.canvas.ctx.save();
    this.components.all().forEach((component) => component.active && component.render(deltaTime, context));
    context.canvas.ctx.restore();
  }
}
