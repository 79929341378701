export class Coordinate {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  static from(start: Coordinate, deltaX: number = 0, deltaY: number = 0) {
    return new Coordinate(start.x - deltaX, start.y - deltaY);
  }
}
