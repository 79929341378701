import { Container, Navbar } from 'react-bootstrap';
import { ROUTER_LINKS } from '../util';

function Header() {
  return (
    <header className="mb-auto">
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href={ROUTER_LINKS.HOME}>Games Portal</Navbar.Brand>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
