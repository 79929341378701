export const enum ResourceTypes {
  CUP,
  PITCHER,
  BOTTLE,
  CAN,
  CONCENTRATE,
  POWDER,
}

export type ResourceDataStruct = {
  title: string;
  baseTimeMs?: number;
  score: number;
  cost: number;
};

export const ResourceData: { [key in ResourceTypes as number]: ResourceDataStruct } = {
  [ResourceTypes.CUP]: {
    title: `Cup`,
    score: 1,
    cost: 0,
  },
  [ResourceTypes.PITCHER]: {
    title: `Pitcher`,
    baseTimeMs: 5000,
    score: 10,
    cost: 25,
  },
  [ResourceTypes.BOTTLE]: {
    title: `Bottle`,
    baseTimeMs: 2000,
    score: 20,
    cost: 250,
  },
  [ResourceTypes.CAN]: {
    title: `Can`,
    baseTimeMs: 3000,
    score: 50,
    cost: 3000,
  },
  [ResourceTypes.CONCENTRATE]: {
    title: `Concentrate`,
    baseTimeMs: 5000,
    score: 100,
    cost: 10000,
  },
  [ResourceTypes.POWDER]: {
    title: `Powder`,
    baseTimeMs: 10000,
    score: 200,
    cost: 30000,
  },
};

export type ResourceState = Partial<{ [key in ResourceTypes as number]: number }>;

export type State = {
  newClock: number;
  oldClock: number;
  score: number;
  gameState: GameState;
  resourceState: ResourceState;
  unlockHistory: UnlockState;
};

export const enum GameState {
  PLAY,
  PAUSE,
}

// Upgrade game data
export type UnlockState = Partial<{ [key in UnlockTypes as number]: boolean }>;
export enum UnlockTypes {
  // Cup Upgrades
  CupHeavyPaper,
  CupPlastic,
  CupHeavyPlasticReusable,
  CupSouvenir,
  CupMetalVacuum,
  CupRecycledPaper,

  // Pitcher Upgrades
  PitcherSmall,
  PitcherMedium,
  PitcherLarge,
  PitcherXL,
  PitcherMetal,
  PitcherAutomated,

  // Bottle Upgrades
  BottleSmall,
  BottleLarge,
  BottleFamily,
  BottleMetalSmall,
  BottleMetalLarge,
  BottleMetalVacuum,

  // Can Upgrades
  CanSmall,
  CanRegular,
  CanTallboy,
  CanMassive,
  CanTwoForOne,
  CanSixPack,
  CanTwelvePack,
  CanTwentyFourPack,
  CanThirtyPack,

  // Concentrate Upgrades
  ConcentrateBottleSmall,
  ConcentrateBottleLarge,
  ConcentrateBottleIndustrial,
  ConcentrateCanSmall,
  ConcentrateCanLarge,
  ConcentrateCanIndustrial,

  // Powder Upgrades
  PowderSingleServe,
  PowderSingleServeSmallPack,
  PowderSingleServeLargePack,
  PowderSingleServeResellerPack,
  PowderSmall,
  PowderLarge,
  PowderIndustrial,
}
export type UnlockDataStruct = {
  title: string;
  description?: string;
  cost: number;
  multiplier: number;
  purchased?: boolean;
  type: ResourceTypes;
};
export type UnlockDataType = { [key in UnlockTypes as number]: UnlockDataStruct };
export const UnlockData: UnlockDataType = {
  // Cup Upgrades
  [UnlockTypes.CupHeavyPaper]: {
    title: 'Heavy Paper Cups',
    description: `Super heavy cups that won't disintegrate with condensation!`,
    cost: 50,
    multiplier: 2,
    type: ResourceTypes.CUP,
  },
  [UnlockTypes.CupPlastic]: {
    title: 'Plastic Cups',
    description: `Plastic cups that retain their shape better.`,
    cost: 500,
    multiplier: 2,
    type: ResourceTypes.CUP,
  },
  [UnlockTypes.CupHeavyPlasticReusable]: {
    title: 'Heavy Reusable Plastic Cup',
    description: `Thicc Plastic cups that make traveling easier.`,
    cost: 1000,
    multiplier: 3,
    type: ResourceTypes.CUP,
  },
  [UnlockTypes.CupSouvenir]: {
    title: 'Souvenir Cup',
    description: `Cups with a cool logo for transporting your lemonade!`,
    cost: 5000,
    multiplier: 3,
    type: ResourceTypes.CUP,
  },
  [UnlockTypes.CupMetalVacuum]: {
    title: 'Metal Vacuum Cup',
    description: `Space-age cups that keep the temps ice cold!`,
    cost: 10000,
    multiplier: 4,
    type: ResourceTypes.CUP,
  },
  [UnlockTypes.CupRecycledPaper]: {
    title: 'Recycled Paper Cup',
    description: `Recycled cups that are eco friendly.`,
    cost: 50000,
    multiplier: 4,
    type: ResourceTypes.CUP,
  },

  // Pitcher Upgrades
  [UnlockTypes.PitcherSmall]: {
    title: 'Small Pitcher',
    description: `A small pitcher for making multiple cups of lemonade at once.`,
    cost: 50,
    multiplier: 2,
    type: ResourceTypes.PITCHER,
  },
  [UnlockTypes.PitcherMedium]: {
    title: 'Medium Pitcher',
    description: `A medium pitcher for making more cups of lemonade.`,
    cost: 100,
    multiplier: 2,
    type: ResourceTypes.PITCHER,
  },
  [UnlockTypes.PitcherLarge]: {
    title: 'Large Pitcher',
    description: `A large pitcher for making a crowd of cups of lemonade.`,
    cost: 1000,
    multiplier: 3,
    type: ResourceTypes.PITCHER,
  },
  [UnlockTypes.PitcherXL]: {
    title: 'XL Pitcher',
    description: `A massive pitcher for making tons of cups of lemonade.`,
    cost: 5000,
    multiplier: 3,
    type: ResourceTypes.PITCHER,
  },
  [UnlockTypes.PitcherMetal]: {
    title: 'Metal Pitcher',
    description: `A temperature controlled pitcher for making better cups of lemonade.`,
    cost: 15000,
    multiplier: 4,
    type: ResourceTypes.PITCHER,
  },
  [UnlockTypes.PitcherAutomated]: {
    title: 'Automatic Pitcher',
    description: `An automated pitcher to lend a helping hand.`,
    cost: 25000,
    multiplier: 4,
    type: ResourceTypes.PITCHER,
  },

  // Bottle Upgrades
  [UnlockTypes.BottleSmall]: {
    title: 'Small Bottles',
    description: `A small bottle of lemonade.`,
    cost: 100,
    multiplier: 2,
    type: ResourceTypes.BOTTLE,
  },
  [UnlockTypes.BottleLarge]: {
    title: 'Large Bottles',
    description: `A large bottle of lemonade.`,
    cost: 1000,
    multiplier: 2,
    type: ResourceTypes.BOTTLE,
  },
  [UnlockTypes.BottleFamily]: {
    title: 'Family-sized Bottles',
    description: `A family-sized jug of lemonade.`,
    cost: 10000,
    multiplier: 3,
    type: ResourceTypes.BOTTLE,
  },
  [UnlockTypes.BottleMetalSmall]: {
    title: 'Metal Bottles',
    description: `A metal jug of lemonade.`,
    cost: 20000,
    multiplier: 3,
    type: ResourceTypes.BOTTLE,
  },
  [UnlockTypes.BottleMetalLarge]: {
    title: 'Large Metal Bottles',
    description: `A large metal jug of lemonade.`,
    cost: 30000,
    multiplier: 4,
    type: ResourceTypes.BOTTLE,
  },
  [UnlockTypes.BottleMetalVacuum]: {
    title: 'Metal Vacuum Bottles',
    description: `A temperature controlled lemonade bottle.`,
    cost: 40000,
    multiplier: 4,
    type: ResourceTypes.BOTTLE,
  },

  // Can Upgrades
  [UnlockTypes.CanSmall]: {
    title: 'Small Cans',
    description: `A small can of lemonade.`,
    cost: 1000,
    multiplier: 2,
    type: ResourceTypes.CAN,
  },
  [UnlockTypes.CanRegular]: {
    title: 'Cans',
    description: `Regular sized cans of lemonade.`,
    cost: 5000,
    multiplier: 2,
    type: ResourceTypes.CAN,
  },
  [UnlockTypes.CanTallboy]: {
    title: 'Tallboy Cans',
    description: `Tallboy cans of lemonade.`,
    cost: 10000,
    multiplier: 3,
    type: ResourceTypes.CAN,
  },
  [UnlockTypes.CanMassive]: {
    title: 'Massive Cans',
    description: `Massive cans of lemonade.`,
    cost: 15000,
    multiplier: 3,
    type: ResourceTypes.CAN,
  },
  [UnlockTypes.CanTwoForOne]: {
    title: '2-for-1 Cans',
    description: `2-for-1 packs of lemonade cans.`,
    cost: 20000,
    multiplier: 4,
    type: ResourceTypes.CAN,
  },
  [UnlockTypes.CanSixPack]: {
    title: '6-pack of Cans',
    description: `6-pack of lemonade cans.`,
    cost: 100000,
    multiplier: 4,
    type: ResourceTypes.CAN,
  },
  [UnlockTypes.CanTwelvePack]: {
    title: '12-pack of Cans',
    description: `12-pack of lemonade cans.`,
    cost: 200000,
    multiplier: 5,
    type: ResourceTypes.CAN,
  },
  [UnlockTypes.CanTwentyFourPack]: {
    title: '24-pack of Cans',
    description: `24-pack of lemonade cans.`,
    cost: 300000,
    multiplier: 5,
    type: ResourceTypes.CAN,
  },
  [UnlockTypes.CanThirtyPack]: {
    title: '30-pack of cans',
    description: `A 30 rack of lemonade cans.`,
    cost: 400000,
    multiplier: 6,
    type: ResourceTypes.CAN,
  },

  // Concentrate Upgrades
  [UnlockTypes.ConcentrateBottleSmall]: {
    title: 'Small Concentrate Bottle',
    description: `A small bottle of lemonade concentrate.`,
    cost: 5000,
    multiplier: 2,
    type: ResourceTypes.CONCENTRATE,
  },
  [UnlockTypes.ConcentrateBottleLarge]: {
    title: 'Large Concentrate Bottle',
    description: `A large bottle of lemonade concentrate.`,
    cost: 10000,
    multiplier: 2,
    type: ResourceTypes.CONCENTRATE,
  },
  [UnlockTypes.ConcentrateBottleIndustrial]: {
    title: 'Industrial Concentrate Bottle',
    description: `An industrial bottle of lemonade concentrate.`,
    cost: 15000,
    multiplier: 3,
    type: ResourceTypes.CONCENTRATE,
  },
  [UnlockTypes.ConcentrateCanSmall]: {
    title: 'Small Concentrate Can',
    description: `A small can of lemonade concentrate.`,
    cost: 20000,
    multiplier: 3,
    type: ResourceTypes.CONCENTRATE,
  },
  [UnlockTypes.ConcentrateCanLarge]: {
    title: 'Large Concentrate Can',
    description: `A large can of lemonade concentrate.`,
    cost: 35000,
    multiplier: 4,
    type: ResourceTypes.CONCENTRATE,
  },
  [UnlockTypes.ConcentrateCanIndustrial]: {
    title: 'Industrial Concentrate Can',
    description: `An industrial can of lemonade concentrate.`,
    cost: 50000,
    multiplier: 4,
    type: ResourceTypes.CONCENTRATE,
  },

  // Powder Upgrades
  [UnlockTypes.PowderSingleServe]: {
    title: 'Single-serve Powder Pack',
    description: `A single-serve pack of lemonade powder.`,
    cost: 5000,
    multiplier: 2,
    type: ResourceTypes.POWDER,
  },
  [UnlockTypes.PowderSingleServeSmallPack]: {
    title: 'Small Single-serve Powder Pack',
    description: `A small pack of single-serve lemonade powder.`,
    cost: 10000,
    multiplier: 2,
    type: ResourceTypes.POWDER,
  },
  [UnlockTypes.PowderSingleServeLargePack]: {
    title: 'Large Single-serve Powder Pack',
    description: `A large pack of single-serve lemonade powder.`,
    cost: 20000,
    multiplier: 3,
    type: ResourceTypes.POWDER,
  },
  [UnlockTypes.PowderSingleServeResellerPack]: {
    title: 'Resellers Single-serve Powder Pack',
    description: `A resellers pack of single-serve lemonade powder.`,
    cost: 30000,
    multiplier: 3,
    type: ResourceTypes.POWDER,
  },
  [UnlockTypes.PowderSmall]: {
    title: 'Small Powder Can',
    description: `A small can of lemonade powder.`,
    cost: 40000,
    multiplier: 4,
    type: ResourceTypes.POWDER,
  },
  [UnlockTypes.PowderLarge]: {
    title: 'Large Powder Can',
    description: `A large can of lemonade powder.`,
    cost: 50000,
    multiplier: 4,
    type: ResourceTypes.POWDER,
  },
  [UnlockTypes.PowderIndustrial]: {
    title: 'Industrial Powder Can',
    description: `An industrial  can of lemonade powder.`,
    cost: 100000,
    multiplier: 5,
    type: ResourceTypes.POWDER,
  },
};
