// canvas rendering props
export const CANVAS_HEIGHT = 400;
export const CANVAS_WIDTH = 400;

// grid rendering props
export const GRID_SIZE: number = 4;
export const GRID_LINE_WIDTH: number = 10;
export const GRID_BORDER_WIDTH: number = 20;
export const X_SLOPE_PAD: number = 10;
export const Y_SLOPE_PAD: number = 10;

// game props
export const MOVE_CLOCK: number = 20000;
